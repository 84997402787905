<template>
  <div>
    <div class="d-flex mb-2 align-start" :style="{ width: '100%' }">
      <component
        :is="tag"
        class="text-h6 flex-grow-1"
        :class="{ title: tag === 'router-link' }"
        :to="{ name: 'organization', params: { orgId: organization.id } }"
        >{{ orgName }}</component
      >
      <img
        v-if="organization && organization.logo"
        :src="organization.logo"
        alt="Логотип организации"
        class="logo mr-2"
      />
    </div>
    <template v-if="organization">
      <v-chip
        v-if="organization.educational_category"
        color="primary"
        small
        outlined
        class="mr-1 mb-1"
        >Образовательная организация</v-chip
      >
      <v-chip
        v-if="organization.subsidiary_count && !listVersion"
        color="primary"
        small
        outlined
        class="mr-1 mb-1"
        >Родительская организация</v-chip
      >
      <option-row title="ID организации:"
        ><router-link
          v-if="withIdLink"
          :to="{
            name: 'organization',
            params: { orgId: organization.id },
          }"
          class="talent-link"
        >
          {{ organization.id
          }}<v-icon class="icon">mdi-open-in-new</v-icon> </router-link
        ><span v-else>
          {{ organization.id }}
        </span>
      </option-row>
      <option-row v-if="address" title="Адрес:">{{ address }}</option-row>

      <template v-if="!listVersion">
        <option-row v-if="educationType" title="Тип учебного заведения:">{{
          educationType
        }}</option-row>
        <v-expansion-panels
          v-if="organization.description"
          flat
          class="black--text text-body-1 pa-0 mb-1"
        >
          <v-expansion-panel @change="isOpenDescription = !isOpenDescription">
            <v-expansion-panel-header class="text-body-1 d-block pa-0"
              ><template #actions>
                <div class="link">
                  {{ isOpenDescription ? "Скрыть" : "Показать" }}
                </div> </template
              ><span class="secondary--text text--lighten-2 mr-1"
                >Описание:</span
              >
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              {{ organization.description }}
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </template>

      <option-row v-if="entityName" title="Юр. Лицо:">{{
        entityName
      }}</option-row>

      <template v-if="entity">
        <option-row v-if="entity.inn" title="ИНН:">{{ entity.inn }}</option-row>
        <template v-if="!listVersion">
          <option-row v-if="entity.legal_address" title="Юр. Адрес:">{{
            entity.legal_address
          }}</option-row>
          <option-row v-if="entity.fact_address" title="Фактический адрес:">{{
            entity.fact_address
          }}</option-row>
        </template>
      </template>

      <template v-if="!listVersion">
        <option-row title="Соглашение действует:"
          >{{ organization.has_actual_agreement ? "Да" : "Нет" }}
        </option-row>
        <option-row title="Юр. Лицо подтверждено:"
          >{{ organization.has_approved_legal_entity ? "Да" : "Нет" }}
        </option-row>
      </template>

      <option-row title="Модерация пройдена:">
        {{ organization.is_permitted ? "Да" : "Нет" }}
      </option-row>
      <option-row
        v-if="organization.is_representative"
        title="Региональный представитель:"
      >
        Данная организация является рег. представителем
      </option-row>

      <template v-if="!listVersion">
        <option-row
          v-if="
            organization.representatives &&
            !!organization.representatives.length
          "
          title="Региональные представители:"
        >
          <template v-for="(item, idx) in organization.representatives">
            <router-link
              :key="item"
              :to="{
                name: 'organization',
                params: { orgId: item },
              }"
              class="talent-link"
            >
              {{ item
              }}<v-icon class="icon">mdi-open-in-new</v-icon> </router-link
            ><span
              v-if="idx + 1 !== organization.representatives.length"
              :key="item + 'commma'"
              >,&emsp;</span
            >
          </template>
        </option-row>
        <option-row v-if="contactFullName" title="Контактное лицо:">{{
          contactFullName
        }}</option-row>

        <option-row v-if="organization.contact_email" title="Эл.почта:"
          ><a :href="`mailto:${organization.contact_email}`">{{
            organization.contact_email
          }}</a></option-row
        >

        <option-row
          v-if="organization.contact_phone"
          title="Телефон контактного лица:"
          >{{ organization.contact_phone }}</option-row
        >
        <option-row v-if="createDate" title="Дата создания:">{{
          createDate
        }}</option-row>

        <option-row v-if="updatedAtDate" title="Дата обновления:">{{
          updatedAtDate
        }}</option-row>
        <option-row v-if="adminLink" title="Панель администратора:"
          ><a
            :href="adminLink"
            target="_blank"
            rel="noopener noreferrer"
            class="link"
            >Перейти</a
          ></option-row
        >
      </template>
    </template>
  </div>
</template>
<script>
const EDUCATION_CATEGORIES = {
  hight: "Высшее",
  secondary: "Среднее",
};
import dayjs from "@/plugins/dayjs";
import { DATE_DATABASE_FORMAT, DATE_DISPLAY_FORMAT } from "@/constants";
import { talentClient } from "@/api";
import { addressJoin } from "@/api/utils/dataParsers";
export default {
  name: "OrgStatisticSection",
  props: {
    organization: Object,
    listVersion: {
      type: Boolean,
      default: false,
    },
    withIdLink: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpenDescription: false,
      entity: null,
    };
  },
  computed: {
    tag() {
      return this.listVersion ? "router-link" : "p";
    },
    orgName() {
      const id = `Организация с ID ${this.$route.params.orgId}`;
      if (!this.organization) return id;
      return this.organization.full_name || this.organization.short_name || id;
    },
    entityName() {
      const { entity, organization } = this;
      if (!organization?.legal_entity) return;
      return (
        entity?.short_name ||
        entity?.name ||
        `ID: ${organization?.legal_entity}`
      );
    },
    address() {
      const { organization } = this;
      if (!organization) return;
      return addressJoin(organization);
    },
    educationType() {
      if (!this.organization) return;
      return EDUCATION_CATEGORIES[this.organization.educational_category];
    },
    contactFullName() {
      const { organization } = this;
      if (!organization) return;
      return [
        organization.contact_last_name,
        organization.contact_first_name,
        organization.contact_middle_name,
      ]
        .filter(Boolean)
        .join(" ");
    },
    adminLink() {
      if (!this.organization.id) return;
      return `${process.env.VUE_APP_TALENT_HOST}/admin/package/organization/${this.organization.id}/change/`;
    },
    createDate() {
      if (!this.organization?.created_at) return;
      return dayjs(this.organization.created_at, DATE_DATABASE_FORMAT).format(
        DATE_DISPLAY_FORMAT
      );
    },
    updatedAtDate() {
      if (!this.organization?.updated_at) return;
      return dayjs(this.organization.updated_at, DATE_DATABASE_FORMAT).format(
        DATE_DISPLAY_FORMAT
      );
    },
  },
  created() {
    if (this.organization?.legal_entity) {
      this.getLegalEntity();
    }
  },
  methods: {
    async getLegalEntity() {
      const ownerId =
        typeof this.organization.admin_users?.[0] === "number"
          ? this.organization.admin_users[0]
          : this.organization.admin_users?.[0]?.user_id;
      if (!ownerId) return;
      try {
        const { data } = await talentClient({
          method: "GET",
          url: `users/${ownerId}/organizations/${this.organization.id}/legal_entity/`,
        });
        this.entity = data;
      } catch {
        // do nothing
      }
    },
  },
};
</script>
<style scoped lang="scss">
.logo {
  width: 100px;
  flex-shrink: 0;
  height: 40px;
  object-fit: contain;
  object-position: center;
  background-position: center;
  background-repeat: no-repeat;
  border: 1px solid var(--v-background-base);
  border-radius: 4px;
}
.v-expansion-panel-header {
  min-height: inherit;
  width: max-content;
}
.title {
  text-decoration: none;
  color: inherit !important;
  transition: color 0.3s;

  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &:hover {
    color: var(--v-anchor-base) !important;
  }
}
</style>
