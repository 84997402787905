<template>
  <div>
    <div class="d-flex mb-2">
      <div class="flex-grow-1">
        <strong>{{ brand.title }}</strong>
      </div>
      <img
        v-if="brand.logo"
        :src="brand.logo"
        alt="Логотип бренда"
        class="logo"
      />
    </div>
    <v-chip v-if="brand.isOwner" color="primary" small class="mb-1"
      >Под управлением организации</v-chip
    >
    <v-chip v-if="!brand.isOwner" color="primary" outlined small class="mb-1"
      >Предоставлены права на использование</v-chip
    >
    <option-row title="ID Бренда:"
      ><talent-link :path="`admin/package/brand/${brand.id}/change/`">{{
        brand.id
      }}</talent-link></option-row
    >
    <option-row v-if="brand.slug" title="Псевдоним:">{{ brand.id }}</option-row>
    <option-row v-if="brand.description" title="Описание:">{{
      brand.description
    }}</option-row>
    <option-row v-if="brand.url" title="Ссылка:">
      <a :href="brand.url" target="_blank" rel="noopener noreferrer">{{
        brand.url
      }}</a>
    </option-row>
    <option-row title="Публичный бренд:">{{
      brand.public_entrance ? "Да" : "Нет"
    }}</option-row>
    <option-row
      v-if="brand.description"
      title="Мероприятий использующих бренд:"
      >{{ brand.events_count || 0 }}</option-row
    >
  </div>
</template>
<script>
import TalentLink from "@/components/TalentLink.vue";
export default {
  name: "BrandCard",
  components: {
    TalentLink,
  },
  props: {
    brand: {
      type: Object,
      required: true,
    },
  },
};
</script>
<style scoped lang="scss">
.logo {
  width: 100px;
  flex-shrink: 0;
  height: 40px;
  object-fit: contain;
  object-position: center;
  background-position: center;
  background-repeat: no-repeat;
  border: 1px solid var(--v-background-base);
  border-radius: 4px;
}
</style>
