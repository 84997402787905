/**
 * Common Talent User data
 * @typedef {Object} TalentUser
 * @property {number} id
 * @property {string?} avatar
 * @property {string} first_name
 * @property {string} last_name
 * @property {string} email
 */

export const getFullName = (userData) => {
  if (!userData) return "";
  const { first_name, last_name, middle_name } = userData;
  return [last_name, first_name, middle_name].filter(Boolean).join(" ");
};

/**
 *
 * @param {object} userData
 * @returns {TalentUser}
 */
export const parseTalentUser = (userData) => {
  const { id, avatar, first_name, last_name, email } = userData;
  return {
    id,
    avatar,
    first_name,
    last_name,
    email,
  };
};

export const addressJoin = (userData) => {
  const {
    country,
    region_with_type,
    city_type_full,
    city,
    settlement_type_full,
    settlement,
  } = userData;
  const cityWithType = [city_type_full, city].filter(Boolean).join(" ");
  const settlementWithType = [settlement_type_full, settlement]
    .filter(Boolean)
    .join(" ");
  return [country, region_with_type, cityWithType, settlementWithType]
    .filter(Boolean)
    .join(", ");
};

export const parseAchivement = (achievement) => {
  const { event, ...rest } = achievement;
  return {
    ...rest,
    event: {
      id: event.id,
      title: event.title,
    },
  };
};
