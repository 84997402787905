<template>
  <info-section
    class="mb-4"
    :total="brands.total"
    :pending="brands.pending"
    icon="mdi-folder-star-multiple"
    title="Бренды"
    :pages="brandsPageCount"
    :current-page="brands.page"
    @setPage="setPage"
  >
    <brand-card
      v-for="brand in displayedBrands"
      :key="brand.id"
      :brand="brand"
      class="item"
  /></info-section>
</template>
<script>
import InfoSection from "@/components/InfoSection.vue";
import BrandCard from "@/components/organizations/BrandCard.vue";
export default {
  name: "BrandsSections",
  components: { InfoSection, BrandCard },
  props: {
    brands: {
      type: Object,
      required: true,
    },
  },
  computed: {
    displayedBrands() {
      const { brands } = this;
      const start = (brands.page - 1) * brands.limit;
      const end = start + brands.limit;
      return brands.list.slice(start, end);
    },
    brandsPageCount() {
      return Math.ceil(this.brands.list.length / this.brands.limit);
    },
  },
  methods: {
    setPage(page) {
      this.$emit("setBrandsPage", page);
    },
  },
};
</script>
<style lang="scss" scoped>
.item {
  border-bottom: 1px solid #c0c0c0;
  padding-bottom: 10px;
  margin-bottom: 10px;
  transition: background-color 0.3s;

  &:last-of-type {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}
</style>
