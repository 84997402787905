<template>
  <div>
    <v-row>
      <v-col lg="6" xs="12" sm="12">
        <v-card class="mb-4" :loading="pending">
          <template slot="progress">
            <v-progress-linear color="yellow darken-2" indeterminate />
          </template>
          <v-toolbar color="primary" dark dense flat>
            <v-toolbar-title class="white--text"
              >Общая информация</v-toolbar-title
            >
          </v-toolbar>
          <v-card-text class="black--text text-body-1">
            <p v-if="pending && !organization" class="text-h6 flex-grow-1">
              Организация с&nbsp;ID{{ $route.params.orgId }}
            </p>
            <organization-card
              v-if="organization"
              :organization="organization"
            />
            <div v-if="error" class="error--text">
              {{ error }}
            </div>
          </v-card-text>
        </v-card>
        <brands-sections
          v-if="organization?.parent_organization"
          :brands="brands"
          @setBrandsPage="setBrandsPage"
        />
      </v-col>
      <v-col lg="6" xs="12" sm="12">
        <parent-organization-section
          v-if="organization?.parent_organization"
          class="mb-4"
          :organization="organization.parent_organization"
        />

        <v-card class="mb-4">
          <v-toolbar color="blue-grey darken-2" dark dense flat>
            <v-toolbar-title class="white--text"
              ><v-icon left>mdi-counter</v-icon>Статистика
              организации</v-toolbar-title
            >
          </v-toolbar>
          <org-statistic-section
            :events-count="eventsCount"
            :organization="organization"
            :members-count="membersCount"
          />
        </v-card>
        <brands-sections
          v-if="organization && !organization.parent_organization"
          :brands="brands"
          @setBrandsPage="setBrandsPage"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { talentClient } from "@/api";
import ParentOrganizationSection from "@/components/organizations/ParentOrganizationSection.vue";
import BrandsSections from "@/components/organizations/BrandsSections.vue";
import OrganizationCard from "@/components/organizations/OrganizationCard.vue";
import OrgStatisticSection from "@/components/organizations/OrgStatisticSection.vue";
import { initialListingModel } from "@/utils";
const initialData = () => ({
  pending: false,
  organization: null,
  error: "",
  eventsCount: null,
  membersCount: null,
  brands: initialListingModel(5),
});

export default {
  name: "OrganizationCommonPage",
  components: {
    ParentOrganizationSection,
    BrandsSections,
    OrgStatisticSection,
    OrganizationCard,
  },
  data() {
    return {
      ...initialData(),
    };
  },
  activated() {
    this.init();
  },
  methods: {
    async getOrganization() {
      this.pending = true;
      this.error = "";
      try {
        const data = await this.$store.dispatch(
          "organizations/getOrganization",
          this.$route.params.orgId
        );
        if (data?.id) {
          this.organization = data;
          const ownedBrands = data.owned_brands.map((brand) => ({
            ...brand,
            isOwner: true,
          }));
          const usedBrands = data.used_brands.map((brand) => ({
            ...brand,
            isOwner: false,
          }));
          const brands = [...ownedBrands, ...usedBrands];
          this.brands.list = brands;
          this.brands.total = brands.length;
          this.brands.pagesCount = Math.ceil(brands.length / this.brands.limit);
          this.pending = false;
          return data;
        }
      } catch (error) {
        console.log("error", error);
        this.error = error;
      }
      this.pending = false;
    },
    async getEventsCount() {
      try {
        const { data } = await talentClient({
          method: "GET",
          url: `/events/`,
          params: {
            limit: 1,
            organization: this.$route.params.orgId,
          },
        });
        this.eventsCount = data.count;
      } catch (error) {
        console.log("error", error);
      }
    },
    async getMembersCount(org) {
      const ownerId = org.admin_users?.[0]?.user_id;
      if (!ownerId) return;
      try {
        const { data } = await talentClient({
          method: "GET",
          url: `/users/${ownerId}/organizations/${org.id}/member/`,
          params: {
            limit: 1,
            only_one_per_user: true,
          },
        });
        this.membersCount = data.count;
      } catch (error) {
        console.log("error", error);
      }
    },
    async init() {
      const data = await this.getOrganization();
      this.getEventsCount();
      if (data?.id) {
        this.getMembersCount(data);
      }
    },
    setBrandsPage(page) {
      this.brands.page = page;
    },
  },
};
</script>
