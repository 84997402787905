<template>
  <a :href="talentUrl" :target="target" class="talent-link">
    <slot></slot>
    <v-icon class="icon">mdi-open-in-new</v-icon>
  </a>
</template>

<script>
export default {
  name: "TalentLink",
  props: {
    path: {
      type: String,
      required: true,
    },
    target: {
      type: String,
      default: "_blank",
    },
  },
  computed: {
    talentUrl() {
      const url = new URL(this.path, process.env.VUE_APP_TALENT_HOST);
      return url.href;
    },
  },
};
</script>

<style lang="scss" scoped>
.talent-link {
  display: inline-flex;
  vertical-align: middle;
  justify-content: flex-start;
  flex-direction: row;
  text-decoration: none;
}
.icon {
  margin-left: 4px;
  font-size: 16px;
}
</style>
