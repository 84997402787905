<template>
  <v-card>
    <v-toolbar color="blue-grey darken-2" dark dense flat>
      <v-toolbar-title class="white--text"
        ><v-icon left>mdi-office-building</v-icon>Родительская
        организация</v-toolbar-title
      >
    </v-toolbar>
    <v-card-text v-if="organization" class="black--text text-body-1">
      <option-row title="ID организации:">{{ organization.id }}</option-row>
      <option-row title="Название:">
        {{ parentName }}
      </option-row>
      <option-row v-if="parentAddress" title="Адрес:">{{
        parentAddress
      }}</option-row>
      <option-row v-if="entityName" title="Юр. Лицо:">{{
        entityName
      }}</option-row>
      <template v-if="entity">
        <option-row v-if="entity.inn" title="ИНН:">{{ entity.inn }}</option-row>
        <option-row v-if="entity.legal_address" title="Юр. Адрес:">{{
          entity.legal_address
        }}</option-row>
        <option-row v-if="entity.legal_address" title="Фактический адрес:">{{
          entity.fact_address
        }}</option-row>
      </template>
      <v-divider></v-divider>
      <v-card-actions class="pa-0">
        <router-link
          is="v-btn"
          class="mt-3"
          color="primary"
          outlined
          small
          :to="`/organizations/${organization.id}`"
          >Перейти к карточке</router-link
        >
      </v-card-actions>
    </v-card-text>
  </v-card>
</template>
<script>
import { addressJoin } from "@/api/utils/dataParsers";
import { talentClient } from "@/api";

export default {
  name: "ParentOrganizationSection",
  props: {
    organization: {
      type: Object,
      Required: true,
    },
  },
  data() {
    return {
      entity: null,
    };
  },
  computed: {
    parentAddress() {
      const { organization } = this;
      if (!organization) return;
      return addressJoin(organization);
    },
    parentName() {
      const { organization } = this;
      if (!organization) return;
      const id = `Организация с ID ${organization.id}`;
      return organization.full_name || organization.short_name || id;
    },
    entityName() {
      const { entity, organization } = this;
      if (!organization?.legal_entity) return;
      return (
        entity?.short_name ||
        entity?.name ||
        `ID: ${organization?.legal_entity}`
      );
    },
  },
  created() {
    this.getLegalEntity();
  },
  methods: {
    async getLegalEntity() {
      const adminId = this.organization?.admin_users?.[0];
      if (!adminId) return;
      try {
        const { data } = await talentClient({
          method: "GET",
          url: `users/${adminId}/organizations/${this.organization.id}/legal_entity/`,
        });
        this.entity = data;
      } catch {
        // do nothing
      }
    },
  },
};
</script>
