import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import "dayjs/locale/ru";
const relativeTime = require("dayjs/plugin/relativeTime");
const customParseFormat = require("dayjs/plugin/customParseFormat");
const localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.locale("ru");
dayjs.extend(utc);
dayjs.extend(relativeTime);
dayjs.extend(customParseFormat);
dayjs.extend(localizedFormat);
export default dayjs;
