<template>
  <v-card-text class="black--text text-body-1">
    <option-row title="Мероприятия:">
      {{ eventsCount ?? 0 }}
    </option-row>
    <option-row title="Участники:">
      {{ membersCount ?? 0 }}
    </option-row>
    <option-row title="Наставники:">
      {{ adminsCount.admins }}
    </option-row>
    <option-row title="Администраторы:">
      {{ adminsCount.owners }}
    </option-row>
    <option-row v-if="organization" title="Дочерние кружки:">
      {{ organization.subsidiary_count ?? 0 }}
    </option-row>
  </v-card-text>
</template>
<script>
export default {
  name: "OrgStatisticSection",
  props: {
    eventsCount: [Number, null],
    membersCount: [Number, null],
    organization: Object,
  },
  computed: {
    adminsCount() {
      const { organization } = this;
      const initial = {
        owners: 0,
        admins: 0,
      };
      if (!organization?.admin_users) {
        return initial;
      }
      return organization.admin_users.reduce((acc, user) => {
        if (user.is_owner) acc.owners++;
        else acc.admins++;
        return acc;
      }, initial);
    },
  },
};
</script>
